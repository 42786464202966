import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { UserSession } from '../models/usersession';

@Injectable()

export class UserSessionService {

  session = new UserSession();
  localStorageSessionKey: string;
  filterArr: any[];


  constructor() {
    this.localStorageSessionKey = 'umis-' + environment.apipublicUrl + '-AuthData';
  }

  create(session) {// jshint ignore:line
    this.setLocalStorageProperties(session);
  }

  destroy() {// jshint ignore:line
    this.setLocalStorageProperties(new UserSession());
    localStorage.removeItem('role');
    localStorage.removeItem('instituteVerified');
    localStorage.removeItem('instituteCourseAvailable');
    localStorage.clear();
  }

  load() { // jshint ignore:line
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData;
  }

  authToken() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).authToken;
  }

  userId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).userId;
  }

  getUserName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).userFullName;
  }

  getFullName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).fullname;
  }

  getUniversityName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).universityname;
  }

  getResetPasswordType() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).ResetPasswordType;
  }

  getotpRequired() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).otpRequired;
  }

  getotpExpire() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).expires_in;
  }

  getInstituteId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).instituteId;
  }

  getInstOwnerShipId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).instituteownershipid;
  }

  getInstituteTypeId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).institutetypeid;
  }

  getUniversityId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).universityId;
  }
  getHodId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).hodId;
  }

  roleId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).roleId;
  }

  studentId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).studentId;
  }

  umisId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).umisId;
  }

  DOB(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).DOB;
  }

  Gender(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).Gender;
  }

  PassportNumber(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).PassportNumber;
  }

  aadhaarnumber(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).aadhaarnumber;
  }

  isekycdone(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? false : JSON.parse(jsonData).isekycdone;
  }

  nodalofficername(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).nodalofficername;
  }

  studentName(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).studentName;
  }

  originUrl(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).originUrl;
  }

  roleName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).roleName;
  }

  getFilterregionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterregionId;
  }
  getFilterDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterdivisionId;
  }
  getFilterSubDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filtersubdivisionId;
  }
  getRegionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).regionId;
  }
  getDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).divisionId;
  }
  getSubDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).subDivisionId;
  }

  getCurrentSubDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).currentSubDivisionId;
  }

  getClientId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).clientId;
  }

  getCurrentClientId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).currentClientId;
  }

  getCurrentClientName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).currentClientName;
  }

  getEmail() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).email;
  }

  getLanguageType() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).languageType;
  }

  referrence2() {
    const jsonData = JSON.parse(localStorage.getItem(this.localStorageSessionKey));
    return JSON.parse(jsonData.departmentId).Services;
  }

  setLocalStorageProperties(session: any) {// jshint ignore:line
    localStorage.setItem(this.localStorageSessionKey, JSON.stringify(session));
  }

  getLocalStorageWithKey(key: any) {// jshint ignore:line
    return localStorage.getItem(key);
  }

  setLocalStorageWithKey(key: any, session: any) {// jshint ignore:line
    localStorage.setItem(key, JSON.stringify(session));
  }

  isDynamicPassword() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData);
  }

  getPageUrl(key: any) {
    this.filterArr = [];
    const menu = JSON.parse(this.getLocalStorageWithKey('menucontrols'))
    const filterItems = menu.map(e => {
      e.submenu.forEach(element => {
        this.filterArr.push(element);
      });
      if (e.submenu.length === 0) {
        this.filterArr.push(e);
      }
    });
    const output = this.filterArr.find(e => {
      return e.path === key;
    })
    return output.controlAccess;
  }

  getDistrictId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).districtId;
  }

  getDepartmentIdId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).departmentId;
  }
  getBlockId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).blockId;
  }
  getFilterDistrictId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterdistrictId;
  }
  getFilterBlockId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterblockId;
  }

  getFilterDate() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterdate;
  }

  emisId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).emisId;
  }


}

