import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment.prod';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ThemeService } from "src/app/services/theme.service";
import { GlobalProvider } from 'src/app/services/backnavigation';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/services/font.service';

@Component({
  selector: 'app-home',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss'],
  // imports: [MatSlideToggleModule],
})
export class HomeComponent implements OnInit {

  imageUrls: string[] = [
    'assets/images/landingpage.jpeg',
    'assets/images/educationbanner.jpg',
    // 'assets/images/landingpage.jpeg'
  ];
  activeIndex = 0;
  public publicUrl = environment.apipublicUrl;
  lang: string;
  tamil: boolean;
  isLightTheme: boolean;
  isDarkTheme: boolean;
  darkModeActive: any;
  language: string;
  darktheme: boolean;
  currentYear = new Date().getFullYear();


  constructor(
    public navigationService: NavigationService,
    private themeService: ThemeService,
    public translate: TranslateService,
    public pages: GlobalProvider,
    private fontService: FontService
  ) {
    this.isDarkTheme = this.themeService.isDarkTheme;
    if (JSON.parse(localStorage.getItem('isLoggedin')) === true) {
      // this.navigationService.goToDashboard();
      this.navigationService.goToHome();
    }
    debugger
    this.pages.language
    this.translate.use(this.pages.language);
  }

  ngOnInit(): void {
    this.language = "English"
  }

  languagechange() {
    this.tamil = !this.tamil
    if (!this.tamil) {
      this.language = "English"
      this.translate.use('en');
      this.lang = 'en'
      this.pages.language = this.lang;
    } else {
      this.language = "தமிழ்"
      this.translate.use('ta');
      this.lang = 'ta'
      this.pages.language = this.lang;
    }
  }

  onThemeSwitchChange() {
    this.isLightTheme = !this.isLightTheme;

    document.body.setAttribute(
      'data-theme',
      this.isLightTheme ? 'light' : 'dark'
    );
  }

  // gotodark(event){
  //   if(event.chcked){
  //     window.location.reload();
  //     this.isDarkTheme = true;
  //     this.themeService.isDarkTheme;
  //   }
  // }
  toggleMode(event: any): void {
    this.darkModeActive = event.target.checked;
    this.themeService.setDarkMode(this.darkModeActive);
  }

  onToggleChange(event: any) {
    if (event.checked) {
      this.darktheme = true;
    } else {
      this.darktheme = false;
    }
    // You can perform any action here based on the toggle state
  }


  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.imageUrls.length; // Increment the active index
  }

  prevSlide() {
    this.activeIndex = (this.activeIndex - 1 + this.imageUrls.length) % this.imageUrls.length; // Decrement the active index
  }

  increaseFontSize(): void {
    this.fontService.increaseFontSize();
  }

  decreaseFontSize(): void {
    this.fontService.decreaseFontSize();
  }

  resetFontSize(): void {
    this.fontService.resetFontSize();
  }


}
