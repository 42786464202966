import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class ReportsService {

    getEventRoute = '/api/user/';
    constructor(private dataService: DataService) {
    };

    forgotpasswordUser(user: any) {
        return this.dataService.post('/api/user/forgetpassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    tripreport(data: any) {
        return this.dataService.post('/api/report', data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    changepasswordUser(user: any) {
        return this.dataService.post('/api/user/ChangePassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    newpaswordUser(user: any) {
        return this.dataService.post('/api/user/newpasword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    downloadReport(query: any) {
        return this.dataService.getExternalData(query);
    }

    saveFile = (blobContent: any, fileName: string, fileType: string) => {
        const byteCharacters = atob(blobContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
    }
}
