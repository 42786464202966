import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { UserSessionService } from 'src/app/services/usersession.service';

@Injectable()
export class AuthGuard implements CanActivate {
  private supportedBrowsers = {
    'Chrome': 116,
    'Firefox': 116,
    'Safari': 10,
    'Edge': 116,
    'IE': 11,
    'Opera': 45
  };
  constructor(private router: Router,
    private userSessionService: UserSessionService,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let menuItems = [];
    const paths = [];
    const menu = this.userSessionService.getLocalStorageWithKey('menu');
    if (menu) {
      menuItems = JSON.parse(menu);
    }
    if(this.checkBrowserSupport()){
      if (localStorage.getItem('isLoggedin')) {
        // if (state.url) return true;
        if (menuItems && menuItems.length > 0 && localStorage.getItem('isLoggedin')) {
          menuItems.forEach(field => {
            if (field.link) { paths.push(field.link); }
            if (field.subItems && field.subItems.length > 0) {
              field.subItems.forEach(sm => { if (sm.link) { paths.push(sm.link); } });
            }
          });
          if (paths.indexOf(state.url) >= 0) {
            return true;
          } else {
            const urls = state.url.split('/');
            if (urls.length > 1 && paths.indexOf('/' + urls[1]) >= 0) {
              return true;
            } else {
              this.router.navigate(['/error']);
            }
          }
        }
      } else {
        this.router.navigate(['/home']);
      }
    }
    else{
      this.router.navigate(['/error']);
    }
    
    // this.router.navigate(['/auth']);
    // this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
    // return true;
  }

  getBrowserInfo(): { name: string, version: number } {
    const ua = navigator.userAgent;
    let tem, match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(match[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: parseInt(tem[1] || '0') };
    }
    if (match[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: parseInt(tem[2]) };
    }
    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) match.splice(1, 1, tem[1]);
    return { name: match[0], version: parseInt(match[1]) };
  }

  isSupportedBrowser(): boolean {
    const browser = this.getBrowserInfo();
    return this.supportedBrowsers[browser.name] && browser.version >= this.supportedBrowsers[browser.name];
  }

  checkBrowserSupport() {
    if (this.isSupportedBrowser()) {
      return true;
    }
    else{
      return false;
    }
  }
}