import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserSessionService } from 'src/app/services/usersession.service';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { UserService } from 'src/app/services/user.service';
import { RoleType } from 'src/enum/roletype';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  form: FormGroup;
  username: '';
  languageType: any;
  Languages: any[];
  user_url: string;
  userdata: any;
  userImg: any;
  userId: number;
  emailId: any;
  roleName: any;
  roleId: number;
  public RoleEnumType = RoleType;
  notificationList: any[];
  active: boolean;
  currentDate: any;
  todayDate: string;
  districtId: number = 0;
  blockId: number = 0;
  districtList: any[];
  blockList: any[];
  filterdistrictList: any[];
  filterblockList: any[];
  userFullName: any;
  universityName: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private userSessionService: UserSessionService,
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    public loaderService: LoaderService,
  ) {

    // Translate Tester Works
    translate.addLangs(['en', 'ta']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ta/) ? browserLang : 'en');


    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|ta|tl|de|af/) ? browserLang : 'en');
    this.languageType = userSessionService.getLanguageType();
    this.userId = userSessionService.userId();
    this.emailId = userSessionService.getEmail();
    this.roleId = this.userSessionService.roleId();
    this.currentDate = moment(new Date()).format('YYYY-MM-DD');

  }

  ngOnInit(): void {
    this.initializeValidators();
    this.username = this.userSessionService.getUserName();
    this.userFullName = this.userSessionService.getFullName();
    this.roleName = this.userSessionService.roleName();
    this.universityName = this.userSessionService.getUniversityName();
    this.getLanguage();
    this.getUserImg(true);
    this.todayDate = moment(new Date()).format('dddd');
  }

  initializeValidators() {

  }

  getUserImg(refresh: boolean) {
  }

  updateParam() {
    const urls = this.router.url.split('?');
    const url2 = this.router.url.split('/');
    if (url2.length === 2) {
      this.router.navigate([urls[0]], {
        queryParams: { data: new Date().getTime() }
      });
    }
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {

    e.preventDefault();
    const title = this.translate.instant('LogoutConfirmation');
    const txt = this.translate.instant('Youwanttologout');
    const Yes = this.translate.instant('Yes');
    const No = this.translate.instant('No');
    swal.fire({
      title,
      text: txt,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: Yes,
      cancelButtonText: No,
    }).then((result) => {
      if (result.value) {
        this.userService.logout(true).subscribe(res => {
          this.navigationService.goToLogin();
          this.authService.logOut();
        });
      }
    })
  }

  getLanguage() {
    // this.utilityService.getLanguageTypeLookup(true).subscribe(res => {
    //   if (res) {
    //     this.Languages = [];
    //     this.Languages = res;
    //     switch (this.languageType) {
    //       case 1:
    //         this.translate.use('en');
    //         break;
    //       case 2:
    //         this.translate.use('ta');
    //         break;
    //       case 3:
    //         this.translate.use('tl');
    //         break;
    //       default:
    //         this.translate.use('en');
    //         break;
    //     }
    //   }
    // });
  }

  openMyProfile() {
    const myuserId = this.userSessionService.userId();
    this.user_url = '/user/' + myuserId;
    this.navigationService.goToProfile(myuserId, 0);
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      autoFocus: false,
      disableClose: true,
      width: "0%",
      height: "0%",
      panelClass: 'mat-dialog'
    })
    dialogRef.afterClosed().subscribe(data => {
      // this.authService.logOut();
      // this.navigationService.goToLogin();
    });
  }

}
