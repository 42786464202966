import { Injectable } from '@angular/core';

@Injectable()
export class GlobalProvider {
  public studentPage: boolean = false;
  public districtView: boolean = false;
  public showCoursePage: boolean = false;
  public showUserPage: boolean = false;
  public showDepartmentPage: boolean = false;
  public showHodPage: boolean = false;
  public showUniversityPage: boolean = false;
  public showInstitutePage: boolean = false;
  public showCourseCreationPage: boolean = false;
  public showBranchPage: boolean = false;
  public sharedData: Number = 0;
  public sharedstudentid: number = 0;
  public language: string = 'en';
  public sheetallocation: boolean = false;
  public isEditConsolidatedData: number = 0;
}