import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class UserService {

    getEventRoute = '/api/user/';
    tokenFromUI: string = "1e2f3g4c5h7a8x9q";
    tokenFromIV: any;

    constructor(private dataService: DataService) {
    };

    getLookUp(refresh: boolean) {
        return this.dataService.getData('/api/user/lookup', refresh);
    }

    getAppVersion(apptype: any, refresh: boolean) {
        return this.dataService.getData('/api/common/version/' + apptype, refresh);
    }

    logout(refresh: boolean) {
        return this.dataService.postAPI('/api/user/logout', {}).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    // save(result: any) {
    //     return this.dataService.post('/api/awc', result).map(response => {
    //         this.dataService.clearRouteCache(this.getEventRoute);
    //         return response;
    //     });
    // }

    getUserLook(subdivision, refresh: boolean) {
        return this.dataService.getData('/api/user/getSubDivisionByUserList/' + subdivision, refresh);
    }

    get(data: any, refresh: boolean) {
        return this.dataService.getData('/api/user/' + data.governmentDepartmentId + '/' + data.hodId + '/' + data.universityId + '/' + data.instituteId, refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/user/' + id, refresh);
    }

    save(result: any) {
        this.tokenFromIV = (Math.random() + ' ').substring(2, 10) + (Math.random() + ' ').substring(2, 10);
        if (result.password) {
            let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
            let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromIV);
            let encrypted = CryptoJS.AES.encrypt(
                JSON.stringify(result.password), _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            var encryptKeyIv = this.tokenFromIV + encrypted.toString();
        }
        result.password = btoa(encryptKeyIv);
        return this.dataService.post('/api/user', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    delete(id: number, status: number) {
        const request = {
            userId: id,
            statusType: status,
        };
        return this.dataService.post('/api/user/updatestatus', request).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    forgotpasswordUser(user: any) {
        return this.dataService.post('/api/user/forgotpassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    resetDefaultPassword(user: any) {
        return this.dataService.post('/api/user/ResettoDefault', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    changepasswordUser(user: any) {
        const request = {
            newPassword: user.newPassword,
            oldPassword: user.oldPassword,
            userId: user.userId
        };

        return this.dataService.post('/api/user/changepassword', request).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    newpaswordUser(user: any) {
        return this.dataService.post('/api/user/newpasword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getUserById(userId: any, refresh: boolean) {
        return this.dataService.getData('/api/user/' + userId, refresh);
    }

    getRoll(refresh: boolean) {
        return this.dataService.getData('/api/user/role', refresh);
    }

    getRole(refresh: boolean) {
        return this.dataService.getData('/api/lookup/roles/' + 1, refresh);
    }

    saveUser(result: any) {
        return this.dataService.post('/api/user', result).map(response => {
            return response;
        });
    }

    getUserImg(userid: any, refresh: any) {
        return this.dataService.getData('/api/user/' + userid, refresh);
    }

    getMenu(refresh: boolean) {
        return this.dataService.getData('/api/user/menus', refresh);
    }

    getInstitutewiswReport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/InstituteWiseReport/' + data.departmentId + '/' + data.hodId + '/' + data.universityId
            + '/' + data.districtId + '/' + data.instituteOwnership + '/' + data.instituteTypeId + '/' + data.instituteId + '/' + data.academicYearId, refresh);
    }

    getLoginSummaryReport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/LoginSummaryReport/' + data.departmentId + '/' + data.hodId + '/' + data.universityId, refresh);
    }

    getLoginSummaryIndividualReport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/LoginSummaryIndividualReport/' + data.departmentId + '/' + data.hodId + '/' + data.universityId + '/' + data.loginType, refresh);
    }

    getStudentDataReport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/StudentReport/' + data.institutionId + '/' + data.academicYearId, refresh);
    }

    getaadhaarmismatchreport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/NotMatchedAadhaarFields/' + data.institutionId, refresh);
    }
    getBankstatusreport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/BankAccountStatus/' + data.institutionId +'/'+ data.ScameType, refresh);
    }

    getBankstatusreport1(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/ReportBankAccountStatus/' + data.institutionId +'/'+ data.ScameType +'/'+ data.eligibleStatus +'/'+data.status, refresh);
    }

    getStudentEKYCStstusreport(data: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/NotMatchedAadhaarFields/' + data.institutionId, refresh);
    }

    // /api/Report/StudentEKYCStstus/{institutionId}/{academicYearId}/{CourseType}/{courseId}/{courseSpecializationId}

    getStudentDataReportUniv(institutionId: any,academicYearId: any,CourseType: any,courseId: any,courseSpecializationId: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/StudentEKYCStstus/' + institutionId + '/' + academicYearId + '/' + CourseType + '/' + courseId + '/' +
        courseSpecializationId , refresh);
    }

    getStudentDataReportUnivid(unversityId: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/Universitywisestudent/' + unversityId, refresh);
    }


    getStudentLoginStstus(institutionId: any, refresh: boolean) {
        return this.dataService.getData('/api/Report/StudentLoginStstus/' + institutionId, refresh);
    }

    // /api/studentacademicinfo/TransferAppliedStudent/{instituteId}

    TransferAppliedStudent(institutionId: any, refresh: boolean) {
        return this.dataService.getData('/api/studentacademicinfo/TransferAppliedStudent/' + institutionId, refresh);
    }

    StudentLoginStstusDashboardcount(institutionId: any, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/StudentLoginStstusDashboard/' + institutionId, refresh);
    }

}
