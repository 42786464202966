// theme.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnDestroy {
  private darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  private theme = new BehaviorSubject<string>('');

  constructor() {
    this.detectSystemTheme(); // Detect initially
    this.darkThemeMediaQuery.addEventListener('change', this.detectSystemTheme.bind(this));
  }

  get isDarkTheme(): boolean {
    return this.darkThemeMediaQuery.matches;
    // return this.theme.asObservable();
  }

  ngOnDestroy() {
    this.darkThemeMediaQuery.removeEventListener('change', this.detectSystemTheme.bind(this));
  }

  private detectSystemTheme() {
    if (this.darkThemeMediaQuery.media) {
      // System theme is dark
      // Apply dark theme styles
      // You can use your theme switching logic here
      // window.location.reload();
    } else {
      // System theme is light
      // Apply light theme styles
      // You can use your theme switching logic here
    }
  }

  setDarkMode(darkModeActive){
    if(darkModeActive){
      document.body.classList.add('');
    }else{
      document.body.classList.add('');
    }
  }
}
