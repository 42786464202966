export class UserSession {
    userId: number;
    instituteId: string;
    hodId: string;
    universityId: string;
    userFullName: string;
    roleId: any;
    authToken: string;
    roleName: string;
    email: string;
    mobileNumber: string;
    languageType: number;
    clientId: any = [];
    currentClientId: any;
    currentClientName: any;
    isDynamicPassword: boolean;
    role: any = [];
    subDivisionId: number;
    regionId: number;
    divisionId: number;
    filterdivisionId: number;
    filterregionId: number
    filtersubdivisionId: number;
    districtId: number;
    blockId: number;
    filterdistrictId: number;
    filterblockId: number;
    fullname: string;
    universityname: string;
    ResetPasswordType: string;
    departmentId: string;
    otpRequired: boolean;
    expires_in: number;
    studentId: number;
    umisId: number;
    DOB: any;
    Gender: any;
    PassportNumber: any;
    aadhaarnumber: any;
    isekycdone: any;
    nodalofficername: any;
    studentName: any;
    originUrl: any;
    instituteownershipid: number;
    institutetypeid: number;
    emisId: number;
    eligiblefortransfer: any;
}
