import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  // selector: 'input[separator]'
  selector: '[separator]',
  providers: [DecimalPipe]
})
export class CommaseperatorDirective {

    constructor(private _inputEl: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (this._inputEl.nativeElement.value === '-') return;
    let commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');
    let toInt: number;
    let toLocale: string;
    if (commasRemoved.split('.').length > 1) {
      let decimal =isNaN(parseInt(commasRemoved.split('.')[1]))? '':parseInt(commasRemoved.split('.')[1]);
      toInt = parseInt(commasRemoved);
      toLocale = toInt.toLocaleString('en-IN') + '.' + decimal;
      console.log(toLocale,'aaaaaaa')
    } else {
      toInt = parseInt(commasRemoved);
      toLocale = toInt.toLocaleString('en-IN');
      console.log(toLocale,'bbbbbbb')
    }
    if (toLocale === 'NaN') {
      this._inputEl.nativeElement.value = '';
    } 
    else {
      this._inputEl.nativeElement.value = toLocale;
      console.log(toLocale,'ccccccc')
    }
  }


  // private subscription: Subscription;

  // constructor(private ngControl: NgControl, private decimal: DecimalPipe) {
  // }

  // ngOnInit() {
  //   const control = this.ngControl.control;
  //   this.subscription = control.valueChanges.pipe(
  //     map(value => {
  //       const parts = value.toString().split(".");
  //       parts[0] = this.decimal.transform(parts[0].replace(/,/g, ''));
  //       return parts.join('.');
  //     })
  //   ).subscribe(v => control.setValue(v, { emitEvent: false }));
  // }

  // ngOnDestroy() {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }
}
