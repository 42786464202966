import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'app-studentpopup',
    templateUrl: './studentpopup.component.html',
    styleUrls: ['./studentpopup.component.scss']
})
export class StudentpopupComponent implements OnInit {

    id: number = 0;
    openKey: any;
    studentResult: any;
    generalStuInfoDisplay: any;
    contactStuInfoDisplay: any;
    familyStuInfoDisplay: any;
    academicStuInfoDisplay: any;
    bankDetailsStuInfoDisplay: any;
    studentSplCategoryName: any;
    studentData: any;
    public indiaId = environment.indiaId;

    constructor(public dialogRef: MatDialogRef<StudentpopupComponent>,
        @Inject(MAT_DIALOG_DATA) public dataRecievedToAdd: any,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private httpClient: HttpClient,
        private dataService: DataService,
        private toaster: ToastrService
    ) {
        this.studentData = this.dataRecievedToAdd.studentData;
    }

    ngOnInit() {
        this.getStudentById();
    }

    getStudentById() {
        this.dataService.getStudentInfobyId(this.studentData.id, true).subscribe(res => {
            if (res) {
                this.studentResult = res;
                this.contactStuInfoDisplay = res.studentContact;
                this.familyStuInfoDisplay = res.studentFamilyInfo;
                this.academicStuInfoDisplay = res.studentCurrentAcademicInfos[0];
                this.bankDetailsStuInfoDisplay = res.bankAccount;
                if (this.studentResult.studentSplCategorys) {
                    this.studentSplCategoryName = this.studentResult.studentSplCategorys.map((v) => v.specialCategory);
                    this.studentSplCategoryName = (this.studentSplCategoryName).toString();
                }
            } if (res && res.isFailure) {
                this.toaster.error(res.htmlFormattedFailures);
            }
        });
    }

    onCancel(isProceed) {
        this.dialogRef.close(isProceed);
    }

}
