import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './usersession.service';
import { DataService } from './data.service';
import { UserSession } from '../models/usersession';
import * as jwtDecode from 'jwt-decode';
import * as momenttz from 'moment-timezone';
import 'rxjs/add/operator/map';
import * as forge from 'node-forge';
import { Router } from '@angular/router';
import { RoleType } from 'src/enum/roletype';

declare var require: any;
const timezone = require('src/assets/json/timezones.json');

@Injectable()
export class AuthenticationService {

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB4vticl4+Wxvhi39+YL+Ui
vwPHnD+OnzjniUb+U1qeY8VnCvGXjZsZzSq6P3vNPIpnPtlP0xUTeIsOvFzDa8W5
OuV3YIwPChPSA71Ng4+j1BqR8tNTpXNljAyk4HFvhS8TOX/vJ3WletwidzY3SNG1
pYkh1S5rlWHlin42O+uO7k2HG8Sup5hSGz2om3beNVoFHYLchLt+E5YhUGmUj5u1
yB+tpboqGZkWSp+W394BwlJdq6Uoo9GGRUYRfSYgxoHKSjOaEvtmxaEsB7nehOP7
Hj5N7em8bHsLQrtdC4xfkGzJMnR3nCuU80qIH1dcDtK3IfqfSD8wbTUyYlgdKrld
AgMBAAE=
  -----END PUBLIC KEY-----`;
  private baseUrl = environment.apipublicUrl;
  timeZones: any[];
  pageAccess = true;
  sessionData = new UserSession();
  tokenFromUI: string = "1e2f3g4c5h7a8x9q";
  tokenFromIV: any;
  isInstituteVerified: boolean;
  instituteCourseAvailable: boolean;
  roleId: number;
  roleEnumType = RoleType;
  // encryptedTemporaryPassword: string = "JPhkFM0L+vT1oLaA4f7En/LL/IfeTjw0V4rnEehH6RU67b5pGf8BfwpOpjZdChrnrrmc9jcWHglIAjwfozRhcz493ipQjC6cJoOTjLEtJBgM2QcsLUMz63o+yUHIq888jW4CKxjP+BIJeXT+zLqiR/6B2WQ0S+InIadzpY9FndKR5L/fewoTXcD3CP0CpSj72oxhdiOLUL5rPa32DCoZhYR+tAH4Rt8J/yp0VYUViajVwe03YngfqvCe4EfwaCEPdUfrtX3X2j7KSy60OSJ1QDQQvAH3npxxtuCKQBNzSZ+1upoOAA+zo3bYc7mL/gKoxd4QVOI1BTbB5L02aHxkgA==";


  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private sessionService: UserSessionService,
    private router: Router,) {
    this.getTimeZones();
    const storedValue = localStorage.getItem('instituteVerified');
    this.isInstituteVerified = JSON.parse(storedValue);

    const storedCourseValue = localStorage.getItem('instituteCourseAvailable');
    this.instituteCourseAvailable = JSON.parse(storedCourseValue);
    this.roleId = this.sessionService.roleId();

  }



  login(usernameandip, password: string, isLogin: boolean, loginPage: boolean) {

   let username = usernameandip.username
   let deviceInfo = usernameandip.ipAddress

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
      });
    let data, apiName;

    if (loginPage) {
      const timeZone = this.getBrowserTimeZone();
      // console.log('timeZone : ', timeZone);

      var rsa = forge.pki.publicKeyFromPem(this.publicKey);
      var encryptedPassword = window.btoa(rsa.encrypt(password));

      data = { username, password: encryptedPassword, deviceInfo, timeZone: "", appType: 0, isForceLogout: isLogin };
      // console.log('data : ', data);
      apiName = '/api/token'
    } else {
      data = { username, otp: password, isForceLogout: isLogin };
      apiName = '/api/verifyloginotp'
    }

    return this.http.post<any>(this.baseUrl + apiName, data, { headers })
      .map(user => {
        // console.log('user : ', user);
        if (user && user?.otp_required) {
          this.sessionData.otpRequired = user.otp_required;
          this.sessionService.create(this.sessionData);
        } else {
          this.sessionData.otpRequired = false;
        }
        if (user && user.expires_in) {
          this.sessionData.expires_in = user.expires_in;
          this.sessionService.create(this.sessionData);
        }
        if (user && user.access_token) {
          // console.log("test", user);
          //this.clearSession();
          const decodedToken = jwtDecode(user.access_token);
          // console.log('22222decodedToken', decodedToken);
          this.sessionData.userFullName = decodedToken['user.username'];
          this.sessionData.roleId = decodedToken['user.roleid'];
          this.sessionData.instituteId = decodedToken['user.instituteid'];
          this.sessionData.hodId = decodedToken['user.hodid'];
          this.sessionData.universityId = decodedToken['user.universityid'];
          this.sessionData.instituteownershipid = decodedToken['user.instituteownershipid'];
          this.sessionData.institutetypeid = decodedToken['user.institutetypeid'];
          this.sessionData.userId = decodedToken['user.id'];
          this.sessionData.fullname = decodedToken['user.fullname'];
          this.sessionData.universityname = decodedToken['user.universityname'] === '0' ? '' : decodedToken['user.universityname'];
          this.sessionData.departmentId = decodedToken['user.departmentId'];
          this.sessionData.districtId = decodedToken['user.districtId'];
          this.sessionData.ResetPasswordType = decodedToken['user.ResetPasswordType'];

          const instituteverified = decodedToken['user.instituteverified'];
          localStorage.setItem('instituteVerified', JSON.stringify(instituteverified === 'True' ? true : false));

          const instituteCourseAvailable = decodedToken['user.instituteCourseAvailable'];
          localStorage.setItem('instituteCourseAvailable', JSON.stringify(instituteCourseAvailable === 'True' ? true : false));

          this.sessionData.authToken = user.access_token;
          if (this.sessionData.roleId == "9") {
            debugger
            this.sessionData.studentId = decodedToken['user.studentId'];
            this.sessionData.umisId = decodedToken['user.umisId'];
            this.sessionData.DOB = decodedToken['user.DOB'];
            this.sessionData.Gender = decodedToken['user.Gender'];
            this.sessionData.PassportNumber = decodedToken['user.PassportNumber'];
            this.sessionData.aadhaarnumber = decodedToken['user.aadhaarnumber'];
            this.sessionData.isekycdone = decodedToken['user.isekycdone'];
            this.sessionData.nodalofficername = decodedToken['user.nodalofficername'];
            this.sessionData.studentName = decodedToken['user.studentName'];
            this.sessionData.emisId = decodedToken['user.emisId'];
            this.sessionData.eligiblefortransfer = decodedToken['user.eligiblefortransfer'];
            this.sessionData.originUrl = window.location.origin;
            localStorage.setItem('initiateStudent', 'true');            
          }
          this.sessionService.create(this.sessionData);
        }
        return user;
        // if (user && user.acces_token) {

        //   this.clearCachedMenu();

        //   const decodedToken = jwtDecode(user.acces_token);

        //   this.sessionData.email = decodedToken['user.email'];
        //   this.sessionData.mobileNumber = decodedToken['user.mobilenumber'];
        //   this.sessionData.authToken = user.acces_token;
        //   this.sessionData.userId = decodedToken['user.id'];
        //   this.sessionData.roleId = decodedToken['user.roleId'];
        //   this.sessionData.roleName = decodedToken['user.rolename'];
        //   this.sessionData.userFullName = decodedToken['user.fullname'];
        //   this.sessionData.isDynamicPassword = decodedToken.referrence1 === 'True';
        //   this.sessionData.districtId = decodedToken['user.districtId'];
        //   this.sessionData.filterblockId = decodedToken['user.blockId'];
        //   this.sessionData.languageType = parseInt(decodedToken['user.languagetype']);


        //   this.sessionService.create(this.sessionData);


        // }

      });
  }

  isAuthenticated() {
    return !!this.sessionService.userId() && !!this.sessionService.authToken();
  }

  isPageAccessAvailable(pageUrl, pageTitle) {

    const rolePages = this.sessionService.getLocalStorageWithKey('role') ? this.sessionService.getLocalStorageWithKey('role') : '[]';
    const pages = JSON.parse(rolePages);
    const paths = ['/unauthorized', '/dashboard']
    // this.roleId == this.roleEnumType.Intsitute && !this.isInstituteVerified ? ['/unauthorized', '/institute'] : 
    //               this.roleId == this.roleEnumType.Intsitute && this.isInstituteVerified && !this.instituteCourseAvailable ? ['/unauthorized', '/institute'] : 
    // const paths = ['/unauthorized', '/dashboard'];
    if (pages && pages.length > 0) {
      pages.forEach(field => {
        if (field.path) {
          paths.push(field.path);
          paths.push(field.title);
        }
        if (field.submenu && field.submenu.length > 0) {
          field.submenu.forEach(field1 => {
            if (field1.path) {
              paths.push(field1.path);
              paths.push(field1.title);
              if (field1.path === '/beneficiaries/1' || field1.path === '/beneficiaries/2') {
                paths.push('beneficiaries');
              }
            }
          });
        }
      });
      this.pageAccess = (paths.indexOf(pageUrl) >= 0) || (paths.indexOf(pageTitle) >= 0) ? true : false;
    }
    return this.pageAccess;
  }

  hasRequiredPermission(permission) {
    for (let i = 0; i < permission.length; i++) {
      if (permission[i] === this.sessionService.roleId()) {
        return true;
      }
    }
    return false;
  }

  getTimeZones() {
    this.timeZones = timezone.timeZone;
  }

  getBrowserTimeZone(): string {
    const zoneName = momenttz.tz.guess();
    const temptimezone = momenttz.tz(zoneName).zoneAbbr();
    const filterZone = this.timeZones.find(i => i.abbr === temptimezone);
    // console.log('get Browser Time zone : ', zoneName, temptimezone, filterZone);

    if (filterZone) {
      // console.log('filterZone.value ', filterZone.value);

      return filterZone.value;
    }
    return '';
  }

  clearCachedMenu() {
    this.dataService.clearCache();
  }

  clearSession() {
    this.sessionService.destroy();
    this.clearCachedMenu();
  }

  logOut() {
    this.router.navigate(['/']);
    this.clearCachedMenu();
    this.sessionService.destroy();
  }
}
