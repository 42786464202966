export enum RoleType {
    Root = 1,
    SuperAdmin = 2,
    Admin = 3,
    Intsitute = 4,
    Hod = 5,
    University = 6,
    Department = 7,
    InstituteUser = 8,
    Student = 9,
    Adminuser = 10,
    collector = 11,
    Executive = 14,
    DSWO = 12,
    EDM = 13
}