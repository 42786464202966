import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumMask'
})
export class PhoneNumMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    const maskedSection = 'XXXXXX';
    const visibleSection = value.slice(-4);
    return `${maskedSection}${visibleSection}`;
  }

}
