import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { UserSessionService } from 'src/app/services/usersession.service';
import { PasswordStrengthValidator } from 'src/app/views/layout/validators/password-strength.validators';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as forge from 'node-forge';
import { NavigationService } from 'src/app/services/navigation.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  form: FormGroup;
  returnUrl: string;
  abcd: number;
  eventData: any;
  userId: number;
  show: boolean;
  show2: boolean;
  showold: boolean;
  captchacode: string;
  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB4vticl4+Wxvhi39+YL+Ui
vwPHnD+OnzjniUb+U1qeY8VnCvGXjZsZzSq6P3vNPIpnPtlP0xUTeIsOvFzDa8W5
OuV3YIwPChPSA71Ng4+j1BqR8tNTpXNljAyk4HFvhS8TOX/vJ3WletwidzY3SNG1
pYkh1S5rlWHlin42O+uO7k2HG8Sup5hSGz2om3beNVoFHYLchLt+E5YhUGmUj5u1
yB+tpboqGZkWSp+W394BwlJdq6Uoo9GGRUYRfSYgxoHKSjOaEvtmxaEsB7nehOP7
Hj5N7em8bHsLQrtdC4xfkGzJMnR3nCuU80qIH1dcDtK3IfqfSD8wbTUyYlgdKrld
AgMBAAE=
  -----END PUBLIC KEY-----`;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private navigationService: NavigationService,
    public loaderService: LoaderService,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    private userSessionService: UserSessionService) {
    if (this.data) {
      this.eventData = JSON.stringify(data);
    }
    this.userId = this.userSessionService.userId();
    this.showold = false;
    this.show = false;
    this.show2 = false;
  }

  ngOnInit() {
    this.initializeValidators();
    this.OnCaptachaValidators();
  }

  initializeValidators() {
    this.form = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, PasswordStrengthValidator]),
      confirmpassword: new FormControl('', [Validators.required]),
      txtCaptcha: new FormControl('', [Validators.required]),
      CaptchaDiv: new FormControl(this.captchacode, [Validators.required]),
      userId: new FormControl(this.userId, [Validators.required]),
    });
  }

  OnCaptachaValidators() {
    this.captchaGenerate();
    this.form.controls['CaptchaDiv'].setValue(this.captchacode);
    this.form.controls['txtCaptcha'].setValue(null);
  }

  captchaGenerate() {
    var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
    var i;
    for (i = 0; i < 6; i++) {
      var a = alpha[Math.floor(Math.random() * alpha.length)];
      var b = alpha[Math.floor(Math.random() * alpha.length)];
      var c = alpha[Math.floor(Math.random() * alpha.length)];
      var d = alpha[Math.floor(Math.random() * alpha.length)];
      var e = alpha[Math.floor(Math.random() * alpha.length)];
      var f = alpha[Math.floor(Math.random() * alpha.length)];
      var g = alpha[Math.floor(Math.random() * alpha.length)];
    }
    this.captchacode = a + b + c + d + e + f + g;
  }

  onSave() {
    this.showold = false;
    this.show = false;
    this.show2 = false;
    if (this.form.valid) {
      if (this.captchacode === this.form.controls['txtCaptcha'].value) {
        if (this.form.controls['newPassword'].value === this.form.controls['confirmpassword'].value) {

          var rsa = forge.pki.publicKeyFromPem(this.publicKey);
          var encryptedPassword = window.btoa(rsa.encrypt(this.form.value.oldPassword));
          this.form.value.oldPassword = (encryptedPassword);

          var encryptedPasswordNew = window.btoa(rsa.encrypt(this.form.value.newPassword));
          this.form.value.newPassword = (encryptedPasswordNew);

          this.userService.changepasswordUser(this.form.value).subscribe(result => {
            if (result && result.isSuccess) {
              this.alertService.success('Password has been Updated Successfully');
              this.dialogRef.close(result);
              this.userService.logout(true).subscribe(res => {
                this.authService.logOut();
                this.navigationService.goToLogin();
                window.location.reload();
              });
            } else {
              if (result && result.failures) {
                this.alertService.error(result.failures.toString());
              }
            }
          });
        }
      } else {
        this.alertService.error('Please enter the vaild captcha');
      }
    } else {
      this.validateFormControl();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  password() {
    this.show = !this.show;
  }

  confirmpassword() {
    this.show2 = !this.show2;
  }

  oldPassword() {
    this.showold = !this.showold;
  }

  validateFormControl() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      }
    })
  }
}
