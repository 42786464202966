import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-stu-validate-popup',
  templateUrl: './stu-validate-popup.component.html',
  styleUrls: ['./stu-validate-popup.component.scss']
})
export class StuValidatePopupComponent implements OnInit {
  emisData: any;
  formData: any;
  generalInfoStudentForm: FormGroup;
  emisStudentForm: FormGroup;
  overallStudentData: any;
  contactData: any;
  umisId: any;
  stdDetails:any={}
  stdDetailsvalue: any;
  constructor(
    public dialogRef: MatDialogRef<StuValidatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dataRecievedToAdd: any,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit() {
    debugger
    this.emisData = this.dataRecievedToAdd.emisData;
    this.formData = this.dataRecievedToAdd.formData;
    this.overallStudentData = this.dataRecievedToAdd.overallStudentData;
    this.umisId = this.dataRecievedToAdd?.umisId
    this.initializeFormData();
    this.initializeEmisData();
    this.emisData?.stuName.toUpper();
    debugger
  }

  initializeFormData() {
    debugger
    this.dataService.getEmisData(this.dataRecievedToAdd.emisData.emsid).subscribe(res => {
      if (res) {
    this.stdDetailsvalue = res.value;
}
});
    this.generalInfoStudentForm = new FormGroup({
      id: new FormControl(this.formData?.id),
      emsid: new FormControl(this.formData?.emsid),
      name: new FormControl(this.formData?.nameAsOnCertificate),
      dateOfBirth: new FormControl(""),
      parentMobileNo: new FormControl(),
      parentName: new FormControl(""),
      aadhaarNumber: new FormControl(this.formData?.aadhaarNumber),
      mobileNumber: new FormControl(this.overallStudentData.studentContact?.mobileNumber),
      fatherName: new FormControl(this.overallStudentData.studentFamilyInfo?.fatherName),
      modherName: new FormControl(this.overallStudentData.studentFamilyInfo?.motherName),
    });
    this.generalInfoStudentForm.controls['dateOfBirth'].setValue(moment(new Date(this.formData.dateOfBirth)).format('YYYY-MM-DD'));
  }

  get formStuGeneral() {
    return this.generalInfoStudentForm.controls;
  }

  initializeEmisData() {
    debugger
    this.emisStudentForm = new FormGroup({
      id: new FormControl(this.formData?.id),
      emsid: new FormControl(this.emisData?.emsid),
      name: new FormControl(this.emisData?.stuName),
      dateOfBirth: new FormControl(this.emisData?.dob),
      parentMobileNo: new FormControl(this.emisData?.mbl_Nmbr),
      parentName: new FormControl(this.emisData?.father_name),
      aadhaarNumber: new FormControl(this.emisData?.aadhaarNumber),
      mobileNumber: new FormControl(this.emisData?.mbl_Nmbr),
      fatherName: new FormControl(this.emisData?.father_name),
      modherName: new FormControl(this.emisData?.mother_name),
    });
  }

  get emisStuGeneral() {
    return this.emisStudentForm.controls;
  }

  onCancel(isProceed) {
    this.dialogRef.close(isProceed);
  }

  onSave() {
    debugger
    if(this.formData?.nameAsOnCertificate === this.emisData.stuName && this.generalInfoStudentForm.value.dateOfBirth === this.emisData.dob
      //  && this.formData.aadhaarNumber === this.emisData.aadhaarNumber && this.overallStudentData.studentContact?.mobileNumber === this.emisData?.mbl_Nmbr
      //  && this.overallStudentData.studentFamilyInfo?.fatherName === this.emisData?.father_name 
      //  && this.overallStudentData.studentFamilyInfo?.motherName === this.emisData?.mother_name
       ){
    let payload = {
      id: 0,
      studentId: this.dataRecievedToAdd.overallStudentData.id,
      eMISJson: JSON.stringify(this.dataRecievedToAdd.emisData),
    }
    this.dataService.saveStudentEmisJson(payload).subscribe((res) => {
      if (res.isSuccess == true) {
        debugger
        this.saveEMISandIsVerified();
      } else {
        this.toaster.error(res.htmlFormattedFailures);
      }
    });
  }else{
    this.toaster.error("EMIS Data Miss Matched")
  }
  }

  saveEMISandIsVerified() {
    debugger
    let payload = {
      id: this.dataRecievedToAdd.overallStudentData.id,
      emisId: this.dataRecievedToAdd.emisData.emsid,
      passed_year:this.stdDetailsvalue?.passed_year,
      udiseCode:this.stdDetailsvalue?.passed_udiseCode,
      tppP_eligible_category:this.stdDetailsvalue?.tppP_eligible_category,
      last_passed_class:this.stdDetailsvalue?.last_passed_class,
      school_type:this.stdDetailsvalue?.school_type,
      medium:this.stdDetailsvalue?.medium
    }
    this.dataService.saveEMISandIsVerified(payload).subscribe((res) => {
      if (res.isSuccess == true) {
        this.toaster.info('Old EMIS Id: ' + this.formData.emsid, 'New EMIS Id: ' + this.emisData.emsid);
        this.dialogRef.close(2)
      } else {
        this.toaster.error(res.htmlFormattedFailures);
      }
    })
  }

 
}
