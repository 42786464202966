import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { environment } from 'src/environments/environment';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthenticationService } from './services/authentication.service';
import { NavigationService } from './services/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './services/user.service';
import swal from 'sweetalert2';
import { UserSessionService } from './services/usersession.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  title = 'UMIS';
  public validinput: RegExp = /^[^\s]+(\s+[^\s]+)*$/;
  isLoggedOutMessage: boolean = false;
  islogin: any;
  instituteId: number;
  constructor(
    dateTimeAdapter: DateTimeAdapter<any>,
    private authService: AuthenticationService,
    private navigation: NavigationService,
    private idle: Idle, private keepalive: Keepalive,
    private toaster: ToastrService,
    private userSessionService: UserSessionService,
    private userService: UserService,
    private router: Router,) {
    dateTimeAdapter.setLocale('en-in');
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.userService.logout(true).subscribe(res => {
        this.navigation.goToLogin();
        this.authService.logOut();
        this.toaster.warning("Your session has timed out. Please log in again to continue.");
      });
      // localStorage.setItem('isLoggedin', 'false');
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      //this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

  }

  @HostListener('document:input', ['$event'])
  handleInput(event: InputEvent): void {
    debugger
    if (event.target instanceof HTMLInputElement) {
      const inputElement = event.target as HTMLInputElement;
      const inputValue = inputElement.value;
      // Replace < and > with empty string
      inputElement.value = inputValue.replace(/[<>]/g, '');
      // Emit an input event to update ngModel or form controls
      inputElement.dispatchEvent(new Event('input'));
    }
  }

  // @HostListener('mouseenter', ['$event'])
  // onMouseEnter(event: MouseEvent) {
  //   // Handle the mouseenter event
  //   if (JSON.parse(localStorage.getItem('isLoggedin')) !== true) {
  //     if (this.isLoggedOutMessage) {
  //       this.toaster.warning("Your session has timed out. Please log in again to continue.");
  //       this.isLoggedOutMessage = false;
  //     }
  //   }
  // }

  // @HostListener('mouseleave', ['$event'])
  // onMouseLeave(event: MouseEvent) {
  //   // Handle the mouseleave event (similar to mouseout)
  //   if (JSON.parse(localStorage.getItem('isLoggedin')) !== true) {
  //     if (this.isLoggedOutMessage) {
  //       this.toaster.warning("Your session has timed out. Please log in again to continue.");
  //       this.isLoggedOutMessage = false;
  //     }
  //   }
  // }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit(): void {
    this.instituteId = parseInt(this.userSessionService.getInstituteId());
    // console.log(this.detectBrowserName());
    //   console.log(this.detectBrowserVersion());
    if(this.instituteId)
      {
        swal.fire({
          title: 'Info',
          html: `
          <ul class="text-justify p-0" style="font-size: 18px;color:black">
            <li>We request all the institutes to send your queries with proof to <a style="color:red" href="mailto:umishelpdesk@tn.gov.in">umishelpdesk@tn.gov.in</a> For further enquiries reach out to our Tollfree Number - 14417 Or contact us on 9344252673 / 9344237663</li>
            <li class="my-2">It has been decided that for the students who have completed their schooling inside Tamil Nadu, Student EMIS ID will be maintained</li>
            <li>And for students who have done their schooling outside Tamil Nadu, a 9 series Student EMIS ID will be generated UMIS portal</li>
          </ul>
        `,
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
      }

    if (environment.production) {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
   
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/monthlyreport'
          || e.url.startsWith('/users/')
          || e.url.startsWith('/dashboard')
        ) {
          document.body.classList.add('removeglobalfilter');
        } else {
          document.body.classList.remove('removeglobalfilter');
        }
      }
    });
  }

  clearAllData() {
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
   

  detectBrowserVersion(){
      var userAgent = navigator.userAgent, tem, 
      
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      console.log(userAgent, '------------------');
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }

      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }

      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];

      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
  }

}