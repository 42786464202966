import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { ExcelService } from 'src/app/services/excel.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-popup-summary',
  templateUrl: './popup-summary.component.html',
  styleUrls: ['./popup-summary.component.scss']
})
export class PopupSummaryComponent implements OnInit, AfterViewInit {

  data = [];
  dataSource = new MatTableDataSource(this.data);
  displayedColumns: string[] = [
    'sno',
    'departmentName',
    'hodName',
    'universityName',
    'districtName',
    'instituteType',
    'instituteOwnership',
    'instituteName',
    'headOfInstitute',
    'headOfInstituteContactNo',
    'nodalOfficer',
    'nodalOfficerContactNo'
  ];
  public excelColumns: string[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  dataSet: any;

  constructor(
    public dialogRef: MatDialogRef<PopupSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public dataRecievedToAdd: any,
    private loaderService: LoaderService,
    private excelService: ExcelService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    debugger
    this.data = this.dataRecievedToAdd.data;
    this.dataSet = this.dataRecievedToAdd.dataSet;
    this.data = _.orderBy(this.data, ['id'], ['desc']);
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getSerialNumber(index: number) {
    if (this.paginator) {
      return this.paginator.pageIndex * this.paginator.pageSize + index + 1;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onCancel(isProceed) {
    this.dialogRef.close(isProceed);
  }

  exportAsXLSX(): void {
    this.loaderService.show();
    setTimeout(() => {
      var exportData = this.data;
      if (!exportData || exportData.length === 0) {
        this.alertService.info("No data available to export");
        this.loaderService.hide();
        return;
      }
      const excelList = [];
      this.excelColumns = [
        "S.No",
        "Department Name",
        "HOD Name",
        "University Name",
        "District Name",
        "Institute Type",
        "Institute Ownership",
        "Institute Name",
        'Head Of Institute',
        'Head Of Institute Contact',
        'Nodal Officer',
        'Nodal Officer Contact'
      ];
      exportData.forEach((a, index) => {
        excelList.push({
          sno: ++index,
          depname: a.departmentName,
          hodname: a.hodName,
          univname: a.universityName,
          distname: a.districtName,
          insttype: a.instituteType,
          instowner: a.instituteOwnership,
          instname: a.instituteName,
          headOfInstitute: a.headOfInstitute,
          headOfInstituteContactNo: a.headOfInstituteContactNo,
          nodalOfficer: a.nodalOfficer,
          nodalOfficerContactNo: a.nodalOfficerContactNo
        });
      });
      this.excelService.exportAsExcelFile(excelList, "Individual Login Summary Report", this.excelColumns);
      this.loaderService.hide();
    }, 500);
  }

}
