// font.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  private currentFontSize: number = parseInt(getComputedStyle(document.body).fontSize); // Initial font size
  increased: boolean;
  decreased: boolean;
  increasedFontSize: number = 0;
  decreasedFontSize: number = 0;

  constructor() { }

  getFontSize(): number {
    return this.currentFontSize;
  }

  increaseFontSize(): void {
    this.increased = true;
    this.decreased = false;
    debugger
    this.increasedFontSize += 2;
    const increased = this.currentFontSize + this.increasedFontSize;
    document.body.style.fontSize = increased + 'px';
  }

  decreaseFontSize(): void {
    this.increased = false;
    this.decreased = true;
    this.decreasedFontSize -= 2;
    document.body.style.fontSize = this.decreasedFontSize + this.currentFontSize + 'px';
  }

  resetFontSize(): void {
    this.currentFontSize = this.currentFontSize;
    document.body.style.fontSize = this.currentFontSize + 'px';
  }
}
