import { environment } from "src/environments/environment";
import { CourseData, dropdownData, hodData, stateInfo, universityData, userData } from "./app.interface";

export class InstituteInfo {
  public static general = "General Information";
  public static contact = "Contact Information";
  public static additional = "Additional Information";
  public static hoi = "Head of the Institute";
  public static NodalOfficer = "Nodal Officer";
  public static completed = "Completed";

  public static true_or_false = [
    { value: "true", label: "Yes" },
    { value: "flase", label: "No" },
  ];

  public static adhar_or_passport = [
    { value: "true", label: "Aadhaar" },
    { value: "flase", label: "Passport" },
  ];
}

export class CourseInfo { }

export class StudentInfo { }

export class LanguageSettings {
  public static languages = ["en", "ta"];
  public static defaultSelectedLang = "en";
  public static setLanguageForMenus = false;
  public static showTranslateOption = false;
  public static fetchTranslateFilesFromAPI = false;
}

export class CommonInfo {
  // Institute Module ( If data is used by more than one component place it in the very first component that is using it)
  // ----------------------------------------------------------------------------------------------------------------------

  // 1. General

  public static univType: dropdownData[];
  public static instOwnership: dropdownData[];
  public static instituteCategory: dropdownData[];
  public static instituteType: dropdownData[];
  public static univName: dropdownData[];
  public static country: dropdownData[];
  public static countryIndia: dropdownData[];
  public static allCountry: dropdownData[];
  public static instituteAcademicYearList: dropdownData[];
  public static academicStatusList: dropdownData[];
  public static instituteCategoryList: dropdownData[];
  public static disabilityType: dropdownData[];
  public static courseGrid: any[];

  public static hodList: dropdownData[];
  public static universityList: dropdownData[];
  public static instituteList: dropdownData[];

  public static yearOfEstablishmentId: any[] = [
    {
      key: 0,
      value: 2023,
    },
    {
      key: 1,
      value: 2022,
    },
    {
      key: 2,
      value: 2021,
    },
    {
      key: 3,
      value: 2020,
    },
    {
      key: 4,
      value: 2019,
    },
    {
      key: 5,
      value: 2018,
    },
    {
      key: 6,
      value: 2017,
    },
    {
      key: 7,
      value: 2016,
    },
    {
      key: 8,
      value: 2015,
    },
    {
      key: 9,
      value: 2014,
    },
    {
      key: 10,
      value: 2013,
    },
    {
      key: 11,
      value: 2012,
    },
    {
      key: 12,
      value: 2011,
    },
    {
      key: 13,
      value: 2010,
    },
    {
      key: 14,
      value: 2009,
    },
    {
      key: 15,
      value: 2008,
    },
    {
      key: 16,
      value: 2007,
    },
    {
      key: 17,
      value: 2006,
    },
    {
      key: 18,
      value: 2005,
    },
    {
      key: 19,
      value: 2004,
    },
    {
      key: 20,
      value: 2003,
    },
    {
      key: 21,
      value: 2002,
    },
    {
      key: 22,
      value: 2001,
    },
    {
      key: 23,
      value: 2000,
    },
    {
      key: 24,
      value: 1999,
    },
    {
      key: 25,
      value: 1998,
    },
    {
      key: 26,
      value: 1997,
    },
    {
      key: 27,
      value: 1996,
    },
    {
      key: 28,
      value: 1995,
    },
    {
      key: 29,
      value: 1994,
    },
    {
      key: 30,
      value: 1993,
    },
    {
      key: 31,
      value: 1992,
    },
    {
      key: 32,
      value: 1991,
    },
    {
      key: 33,
      value: 1990,
    },
    {
      key: 34,
      value: 1989,
    },
    {
      key: 35,
      value: 1988,
    },
    {
      key: 36,
      value: 1987,
    },
    {
      key: 37,
      value: 1986,
    },
    {
      key: 38,
      value: 1985,
    },
    {
      key: 39,
      value: 1984,
    },
    {
      key: 40,
      value: 1983,
    },
    {
      key: 41,
      value: 1982,
    },
    {
      key: 42,
      value: 1981,
    },
    {
      key: 43,
      value: 1980,
    },
    {
      key: 44,
      value: 1979,
    },
    {
      key: 45,
      value: 1978,
    },
    {
      key: 46,
      value: 1977,
    },
    {
      key: 47,
      value: 1976,
    },
    {
      key: 48,
      value: 1975,
    },
    {
      key: 49,
      value: 1974,
    },
    {
      key: 50,
      value: 1973,
    },
    {
      key: 51,
      value: 1972,
    },
    {
      key: 52,
      value: 1971,
    },
    {
      key: 53,
      value: 1970,
    },
    {
      key: 54,
      value: 1969,
    },
    {
      key: 55,
      value: 1968,
    },
    {
      key: 56,
      value: 1967,
    },
    {
      key: 57,
      value: 1966,
    },
    {
      key: 58,
      value: 1965,
    },
    {
      key: 59,
      value: 1964,
    },
    {
      key: 60,
      value: 1963,
    },
    {
      key: 61,
      value: 1962,
    },
    {
      key: 62,
      value: 1961,
    },
    {
      key: 63,
      value: 1960,
    },
    {
      key: 64,
      value: 1959,
    },
    {
      key: 65,
      value: 1958,
    },
    {
      key: 66,
      value: 1957,
    },
    {
      key: 67,
      value: 1956,
    },
    {
      key: 68,
      value: 1955,
    },
    {
      key: 69,
      value: 1954,
    },
    {
      key: 70,
      value: 1953,
    },
    {
      key: 71,
      value: 1952,
    },
    {
      key: 72,
      value: 1951,
    },
    {
      key: 73,
      value: 1950,
    },
    {
      key: 74,
      value: 1949,
    },
    {
      key: 75,
      value: 1948,
    },
    {
      key: 76,
      value: 1947,
    },
    {
      key: 77,
      value: 1946,
    },
    {
      key: 78,
      value: 1945,
    },
    {
      key: 79,
      value: 1944,
    },
    {
      key: 80,
      value: 1943,
    },
    {
      key: 81,
      value: 1942,
    },
    {
      key: 82,
      value: 1941,
    },
    {
      key: 83,
      value: 1940,
    },
    {
      key: 84,
      value: 1939,
    },
    {
      key: 85,
      value: 1938,
    },
    {
      key: 86,
      value: 1937,
    },
    {
      key: 87,
      value: 1936,
    },
    {
      key: 88,
      value: 1935,
    },
    {
      key: 89,
      value: 1934,
    },
    {
      key: 90,
      value: 1933,
    },
    {
      key: 91,
      value: 1932,
    },
    {
      key: 92,
      value: 1931,
    },
    {
      key: 93,
      value: 1930,
    },
    {
      key: 94,
      value: 1929,
    },
    {
      key: 95,
      value: 1928,
    },
    {
      key: 96,
      value: 1927,
    },
    {
      key: 97,
      value: 1926,
    },
    {
      key: 98,
      value: 1925,
    },
    {
      key: 99,
      value: 1924,
    },
    {
      key: 100,
      value: 1923,
    },
    {
      key: 101,
      value: 1922,
    },
    {
      key: 102,
      value: 1921,
    },
    {
      key: 103,
      value: 1920,
    },
    {
      key: 104,
      value: 1919,
    },
    {
      key: 105,
      value: 1918,
    },
    {
      key: 106,
      value: 1917,
    },
    {
      key: 107,
      value: 1916,
    },
    {
      key: 108,
      value: 1915,
    },
    {
      key: 109,
      value: 1914,
    },
    {
      key: 110,
      value: 1913,
    },
    {
      key: 111,
      value: 1912,
    },
    {
      key: 112,
      value: 1911,
    },
    {
      key: 113,
      value: 1910,
    },
    {
      key: 114,
      value: 1909,
    },
    {
      key: 115,
      value: 1908,
    },
    {
      key: 116,
      value: 1907,
    },
    {
      key: 117,
      value: 1906,
    },
    {
      key: 118,
      value: 1905,
    },
    {
      key: 119,
      value: 1904,
    },
    {
      key: 120,
      value: 1903,
    },
    {
      key: 121,
      value: 1902,
    },
    {
      key: 122,
      value: 1901,
    },
    {
      key: 123,
      value: 1900,
    },
  ];

  // 2. Contact

  public static state: any[];

  // 3. Additional
  public static accountType: dropdownData[];

  public static bankBranchId: dropdownData[] = [
    {
      key: 1,
      value: "branchId 1",
    },
    {
      key: 2,
      value: "branchId 2",
    },
    {
      key: 3,
      value: "branchId 3",
    },
  ];

  // 4. HOI ------------------------------------

  public static gender: any[];
  public static salutation: dropdownData[];
  public static designation: dropdownData[];

  // 5. Nodal Officer

  // Student Module
  // ----------------------------------------------------------------------------------------------------------------------
  // 1. General
  public static bloodGroup: dropdownData[];
  // 2. Contact
  // 3. Family
  public static familyAnnualIncome: dropdownData[];
  public static familyCardType: dropdownData[];
  public static fatherOccupation: dropdownData[];
  public static motherOccupation: dropdownData[];
  public static guardianOccupation: dropdownData[];
  public static isOrphan: dropdownData[];
  public static academicYearId: any = [];
  public static academicYearAll: dropdownData[];
  // 4. Academic
  public static academicYear: dropdownData[] = [
    {
      key: 0,
      value: "2022-2023",
    },
    {
      key: 1,
      value: "2021-2022",
    },
    {
      key: 2,
      value: "2020-2021",
    },
    {
      key: 3,
      value: "2019-2020",
    },
    {
      key: 4,
      value: "2018-2019",
    },
    {
      key: 5,
      value: "2017-2018",
    },
    {
      key: 6,
      value: "2016-2017",
    },
    {
      key: 7,
      value: "2015-2016",
    },
    {
      key: 8,
      value: "2014-2015",
    },
    {
      key: 9,
      value: "2013-2014",
    },
    {
      key: 10,
      value: "2012-2013",
    },
    {
      key: 11,
      value: "2011-2012",
    },
    {
      key: 12,
      value: "2010-2011",
    },
    {
      key: 13,
      value: "2009-2010",
    },
    {
      key: 14,
      value: "2008-2009",
    },
    {
      key: 15,
      value: "2007-2008",
    },
    {
      key: 16,
      value: "2006-2007",
    },
    {
      key: 17,
      value: "2005-2006",
    },
    {
      key: 18,
      value: "2004-2005",
    },
    {
      key: 19,
      value: "2003-2004",
    },
    {
      key: 20,
      value: "2002-2003",
    },
    {
      key: 21,
      value: "2001-2002",
    },
    {
      key: 22,
      value: "2000-2001",
    },
    {
      key: 23,
      value: "1999-2000",
    },
    {
      key: 24,
      value: "1998-1999",
    },
    {
      key: 25,
      value: "1997-1998",
    },
    {
      key: 26,
      value: "1996-1997",
    },
    {
      key: 27,
      value: "1995-1996",
    },
    {
      key: 28,
      value: "1994-1995",
    },
    {
      key: 29,
      value: "1993-1994",
    },
    {
      key: 30,
      value: "1992-1993",
    },
    {
      key: 31,
      value: "1991-1992",
    },
    {
      key: 32,
      value: "1990-1991",
    },
    {
      key: 33,
      value: "1989-1990",
    },
    {
      key: 34,
      value: "1988-1989",
    },
    {
      key: 35,
      value: "1987-1988",
    },
    {
      key: 36,
      value: "1986-1987",
    },
    {
      key: 37,
      value: "1985-1986",
    },
    {
      key: 38,
      value: "1984-1985",
    },
    {
      key: 39,
      value: "1983-1984",
    },
    {
      key: 40,
      value: "1982-1983",
    },
    {
      key: 41,
      value: "1981-1982",
    },
    {
      key: 42,
      value: "1980-1981",
    },
    {
      key: 43,
      value: "1979-1980",
    },
    {
      key: 44,
      value: "1978-1979",
    },
    {
      key: 45,
      value: "1977-1978",
    },
    {
      key: 46,
      value: "1976-1977",
    },
    {
      key: 47,
      value: "1975-1976",
    },
    {
      key: 48,
      value: "1974-1975",
    },
    {
      key: 49,
      value: "1973-1974",
    },
    {
      key: 50,
      value: "1972-1973",
    },
    {
      key: 51,
      value: "1971-1972",
    },
    {
      key: 52,
      value: "1970-1971",
    },
    {
      key: 53,
      value: "1969-1970",
    },
    {
      key: 54,
      value: "1968-1969",
    },
    {
      key: 55,
      value: "1967-1968",
    },
    {
      key: 56,
      value: "1966-1967",
    },
    {
      key: 57,
      value: "1965-1966",
    },
    {
      key: 58,
      value: "1964-1965",
    },
    {
      key: 59,
      value: "1963-1964",
    },
    {
      key: 60,
      value: "1962-1963",
    },
    {
      key: 61,
      value: "1961-1962",
    },
    {
      key: 62,
      value: "1960-1961",
    },
    {
      key: 63,
      value: "1959-1960",
    },
    {
      key: 64,
      value: "1958-1959",
    },
    {
      key: 65,
      value: "1957-1958",
    },
    {
      key: 66,
      value: "1956-1957",
    },
    {
      key: 67,
      value: "1955-1956",
    },
    {
      key: 68,
      value: "1954-1955",
    },
    {
      key: 69,
      value: "1953-1954",
    },
    {
      key: 70,
      value: "1952-1953",
    },
    {
      key: 71,
      value: "1951-1952",
    },
    {
      key: 72,
      value: "1950-1951",
    },
  ];
  public static modeOfStudy: dropdownData[];
  public static courseSpecializationId: dropdownData[] = [
    {
      key: 1,
      value: "branchSpecialization 1",
    },
    {
      key: 2,
      value: "branchSpecialization 2",
    },
    {
      key: 3,
      value: "branchSpecialization 3",
    },
  ];
  public static courseId: dropdownData[] = [
    {
      key: 1,
      value: "course 1",
    },
    {
      key: 2,
      value: "course 2",
    },
    {
      key: 3,
      value: "course 3",
    },
  ];
  public static typeOfAdmission: dropdownData[];
  public static remarks: dropdownData[];

  public static SpecialCategory: any[];

  public static true_or_false = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  public static adhar_or_passport = [
    { value: true, label: "Aadhaar" },
    { value: false, label: "Passport" },
  ];

  public static true_or_false_first_graduate = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Not Applicable", label: "Not Applicable" },
  ];

  // Course Module
  // ----------------------------------------------------------------------------------------------------------------------

  public static courseName: any[] = [];
  public static courseSpecialization: any[] = [];
  public static courseDuration: any[] = [];
  public static maxcourseDuration: any[] = [];

  public static courses: CourseData[] = [
    {
      id: 1,
      courseName: "B.E",
      courseSpecialization: "ECE",
      courseDuration: "4 Years",
    },
    {
      id: 2,
      courseName: "B.E",
      courseSpecialization: "CSE",
      courseDuration: "4 Years",
    },
    {
      id: 3,
      courseName: "B.E",
      courseSpecialization: "EEE",
      courseDuration: "4 Years",
    },
    {
      id: 4,
      courseName: "B.Tech",
      courseSpecialization: "IT",
      courseDuration: "4 Years",
    },
    {
      id: 5,
      courseName: "B.Sc",
      courseSpecialization: "CSE",
      courseDuration: "3 Years",
    },
    {
      id: 6,
      courseName: "B.Sc",
      courseSpecialization: "Maths",
      courseDuration: "3 Years",
    },
    {
      id: 7,
      courseName: "B.Sc",
      courseSpecialization: "Physics",
      courseDuration: "3 Years",
    },
    {
      id: 8,
      courseName: "B.A",
      courseSpecialization: "English",
      courseDuration: "3 Years",
    },
    {
      id: 9,
      courseName: "B.A",
      courseSpecialization: "Tamil",
      courseDuration: "3 Years",
    },
    {
      id: 10,
      courseName: "B.Com",
      courseSpecialization: "CA",
      courseDuration: "3 Years",
    },
  ];

  // User Managemnet Module

  // Hod

  public static hodRole: dropdownData[] = [
    {
      key: 0,
      value: "Head of Institute",
    },
    {
      key: 1,
      value: "Nodal Officer of Institute",
    },
    {
      key: 2,
      value: "Nodal Officer of University",
    },
  ];

  public static hodData: hodData[] = [
    {
      id: 1,
      hodName: "Kala Rajan",
      hodMobile: "9876543211",
      hodEmail: "test01@mail.com",
      hodRole: "Nodal Officer of Institute",
      hodStatus: "Active",
    },
    {
      id: 2,
      hodName: "Bala Subramani",
      hodMobile: "9876543212",
      hodEmail: "test02@mail.com",
      hodRole: "Nodal Officer of University",
      hodStatus: "Active",
    },
    {
      id: 3,
      hodName: "Abu Mohammed",
      hodMobile: "9876543213",
      hodEmail: "test03@mail.com",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
    {
      id: 4,
      hodName: "David Miller",
      hodMobile: "9876543214",
      hodEmail: "test04@mail.com",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
  ];

  // University

  public static universityName: dropdownData[] = [
    {
      key: 0,
      value: "Anna University",
    },
    {
      key: 1,
      value: "Kamaraj University",
    },
    {
      key: 2,
      value: "Bharathidasan University",
    },
  ];

  public static universityData: universityData[] = [
    {
      id: 1,
      aisheId: "ABC0015",
      universityName: "Bharathidasan University",
      hodRole: "Nodal Officer of University",
      hodStatus: "Active",
    },
    {
      id: 2,
      aisheId: "ABC2011",
      universityName: "Kamaraj University",
      hodRole: "Nodal Officer of Institute",
      hodStatus: "Active",
    },
    {
      id: 3,
      aisheId: "ABC0512",
      universityName: "Anna University",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
    {
      id: 4,
      aisheId: "ABC1012",
      universityName: "Anna University",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
  ];

  // Institute

  public static instituteData: universityData[] = [
    {
      id: 1,
      aisheId: "ABC0015",
      universityName: "Bharathidasan University",
      hodRole: "Nodal Officer of University",
      hodStatus: "Active",
    },
    {
      id: 2,
      aisheId: "ABC2011",
      universityName: "Kamaraj University",
      hodRole: "Nodal Officer of Institute",
      hodStatus: "Active",
    },
    {
      id: 3,
      aisheId: "ABC0512",
      universityName: "Anna University",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
    {
      id: 4,
      aisheId: "ABC1012",
      universityName: "Anna University",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
  ];

  // User

  public static userData: userData[] = [
    {
      id: 1,
      hodName: "Kala Rajan",
      hodMobile: "9876543211",
      hodEmail: "test01@mail.com",
      hodRole: "Nodal Officer of Institute",
      hodStatus: "Active",
    },
    {
      id: 2,
      hodName: "Bala Subramani",
      hodMobile: "9876543212",
      hodEmail: "test02@mail.com",
      hodRole: "Nodal Officer of University",
      hodStatus: "Active",
    },
    {
      id: 3,
      hodName: "Abu Mohammed",
      hodMobile: "9876543213",
      hodEmail: "test03@mail.com",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
    {
      id: 4,
      hodName: "David Miller",
      hodMobile: "9876543214",
      hodEmail: "test04@mail.com",
      hodRole: "Head of Institute",
      hodStatus: "Active",
    },
  ];

  // State Management
  public static generalInstData: any;
  public static contactInstData: any;
  public static hoiInstData: any;
  public static nodalInstData: any;
}
