import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNoSpaceInput]'
})
export class NoSpaceInputDirective {

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const newValue = inputValue.replace(/\s/g, ''); // Remove space characters
    if (newValue !== inputValue) {
      inputElement.value = newValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }
}
