import { Component } from '@angular/core';

@Component({
  selector: 'app-downtime-page',
  templateUrl: './downtime-page.component.html',
  styleUrls: ['./downtime-page.component.scss']
})
export class DowntimePageComponent {
  downtimeInfo: any[] = []; // Placeholder for the downtime information

  constructor() {
    // Simulating API call to fetch downtime information
    this.fetchDowntimeInfo();
  }

  fetchDowntimeInfo() {
    // Simulating API response with downtime information
    this.downtimeInfo = [
      { from: '06-07-2023, 7:00 PM', to: '09-07-2023, 7:00 PM', duration: '2 days' },
      // Add more downtime entries as needed
    ];
  }
}
