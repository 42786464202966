import { NgModule } from '@angular/core';
import { OnlyNumber } from "./onlynumber.directive";
import { SpecialCharacter } from "./onlychar.directive";
import { onlycharSpecialCharacter } from './onlycharSpecial.directive';
import { onlynumberSpecialCharacter } from './onlynumberSpecial.directive';
import { inputText } from './inputtext.directive';
import { PasswordOnly } from './password.directive';
import { TimeOnly } from './time.directive';
import { RoleDirective } from './role.directive';
import { AadharMaskDirective } from './aadhar-mask.directive';
import { CommaseperatorDirective } from './commaseperator.directive';
import { NoSpaceInputDirective } from './no-space-input.directive';
import { PhoneNumMaskPipe } from './phone-num-mask.pipe';


export const components = [
  OnlyNumber, SpecialCharacter, onlycharSpecialCharacter,NoSpaceInputDirective,PhoneNumMaskPipe,
  onlynumberSpecialCharacter, inputText, PasswordOnly, TimeOnly, RoleDirective,CommaseperatorDirective
];

@NgModule({
  declarations: [components, PhoneNumMaskPipe],
  exports: [components]
})
export class DirectivesModule { }
