import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  type: any;
  title: any;
  desc: any;
  private sub: Subscription;
  isVersionIssueShow: boolean = false;
  private supportedBrowsers = {
    'Chrome': 116,
    'Firefox': 116,
    'Safari': 10,
    'Edge': 116,
    'IE': 11,
    'Opera': 45
  };
  constructor(private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userService: UserService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    this.checkBrowserSupport();
    this.type = this.route.snapshot.paramMap.get('type');
    this.sub = this.route.data.subscribe(param => {
      if (param.type) {
        this.type = param.type;
      }
      if (param.title) {
        this.title = param.title;
      }
      if (param.desc) {
        this.desc = param.desc
      }
    });

    switch (this.type) {
      case '404':
        if (!this.title) {
          this.title = 'Page Not Found'
        }
        if (!this.desc) {
          this.desc = 'Oopps!! The page you were looking for doesn\'t exist.'
        }
        break;
      case '500':
        if (!this.title) {
          this.title = 'Internal server error'
        }
        if (!this.desc) {
          this.desc = 'Oopps!! There wan an error. Please try agin later.'
        }
        break;
      default:
        this.type = 'Ooops..';
        if (!this.title) {
          this.title = 'Something went wrong';
        }
        if (!this.desc) {
          this.desc = 'Looks like something went wrong.<br>' + 'We\'re working on it';
        }
    }
  }

  backToLogin() {
    if (JSON.parse(localStorage.getItem('isLoggedin')) === true) {
      this.userService.logout(true).subscribe(res => {
        this.navigationService.goToLogin();
        this.authService.logOut();
      });
    } else {
      this.navigationService.goToLogin();
      this.authService.logOut();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getBrowserInfo(): { name: string, version: number } {
    const ua = navigator.userAgent;
    let tem, match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(match[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: parseInt(tem[1] || '0') };
    }
    if (match[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: parseInt(tem[2]) };
    }
    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) match.splice(1, 1, tem[1]);
    return { name: match[0], version: parseInt(match[1]) };
  }

  isSupportedBrowser(): boolean {
    const browser = this.getBrowserInfo();
    return this.supportedBrowsers[browser.name] && browser.version >= this.supportedBrowsers[browser.name];
  }

  checkBrowserSupport(): void {
    if (!this.isSupportedBrowser()) {
      this.isVersionIssueShow = true;
    }
  }

}
