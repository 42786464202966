import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonInfo } from '../../app.config';

@Component({
    selector: 'app-studentrejecttype',
    templateUrl: './studentrejecttype.component.html',
    styleUrls: ['./studentrejecttype.component.scss']
})
export class StudentrejecttypeComponent implements OnInit {

    id: number = 0;
    openKey: any;
    studentData: any;
    form: FormGroup;
    commondata = CommonInfo;

    constructor(public dialogRef: MatDialogRef<StudentrejecttypeComponent>,
        @Inject(MAT_DIALOG_DATA) public dataRecievedToAdd: any,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private httpClient: HttpClient,
        private dataService: DataService,
        private toaster: ToastrService,
    ) {
        debugger
        this.studentData = this.dataRecievedToAdd.name;
    }

    ngOnInit() {
        this.initializeData();
        this.dataService.getDropdownData(35, 1); // UniversityType ,
    }

    initializeData() {
        this.form = this.formBuilder.group({
            remarksId: [null, [Validators.required]]
        });
    }

    get formData() {
        return this.form.controls;
    }

    reject() {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value.remarksId);
        } else {
            this.form.markAllAsTouched();
        }
    }

    onCancel(isProceed) {
        this.dialogRef.close(isProceed);
    }

}
