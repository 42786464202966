export const environment = {
  apiBaseUrl: 'https://xtsapi',
  imageBaseUrl: 'https://umisimages.s3.ap-south-1.amazonaws.com/Attachments/',
  apipublicUrl: 'https://umisapi.tnega.org', 

  tokenEndPoint: '/api/token',
  production: true,
  environment: 'PROD',
  showEnvironment: false,
  indiaId: 83, //India Id used in student contact and student Info
};