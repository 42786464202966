import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';
import { HttpInterceptorService } from './services/interceptor.service';
import { UserSessionService } from './services/usersession.service';
import { RoleService } from './services/role.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationService } from './services/navigation.service';
import { ExcelService } from './services/excel.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ngx-lightbox';
import { UserIdleModule } from 'angular-user-idle';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from './views/layout/directives/directives.module';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowDistrictReportModule } from './views/pages/reports/show-districtwisereport/show-district-report.module';
import { LanguageSettings } from './views/pages/app.config';
import { environment } from 'src/environments/environment';


import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { StudentpopupComponent } from './views/pages/dashboard/studentpopup/studentpopup.component';
import { StudentrejecttypeComponent } from './views/pages/student/studentrejecttype/studentrejecttype.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StuValidatePopupComponent } from './views/pages/student/stu-validate-popup/stu-validate-popup.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoaderModule } from './views/layout/loader/loader.module';
import { GlobalProvider } from './services/backnavigation';
import { PopupSummaryComponent } from './views/pages/reports/login-summary/popup-summary/popup-summary.component';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { AnnouncementComponent } from './views/pages/reports/announcement/announcement.component';
// import { TemphomeComponent } from './views/pages/temphome/temphome.component';
// import { RouteReuseStrategy } from '@angular/router';
// import { CustomReuseStrategy } from './custom-reuse-strategy';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export function HttpLoaderFactory(httpClient: HttpClient) {
//   const checkLang = LanguageSettings["fetchTranslateFilesFromAPI"]
//     ? LanguageSettings["fetchTranslateFilesFromAPI"]
//     : false;
//   if (checkLang) {
//     return new TranslateHttpLoader(
//       httpClient,
//       `${environment.apiBaseUrl}` + "public/adminLanguageFile/",
//       ".json"
//     );
//   } else {
//     return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
//   }
// }


@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    StudentpopupComponent,
    StudentrejecttypeComponent,
    StuValidatePopupComponent,
    PopupSummaryComponent,
    AnnouncementComponent,
    // TemphomeComponent,
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShowDistrictReportModule,
    LayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    DirectivesModule,
    MatSelectModule,
    NgOtpInputModule,
    NgSelectModule,
    MatSelectFilterModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgbDropdownModule,
    HttpClientModule,
    LoaderModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LightboxModule,
    UserIdleModule.forRoot({ idle: 30, timeout: 10, ping: 30 }),
    LoaderModule
  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    AuthGuard,
    NavigationService,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    },
    DataService,
    AuthenticationService,
    AlertService,
    RoleService,
    ExcelService,
    UserSessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    GlobalProvider
  ],
  bootstrap: [AppComponent],
  entryComponents: [
  ],

})
export class AppModule { }